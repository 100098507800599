import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import FullScreenContainer from "../components/full-screen-container"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StyledH1, StyledP } from "../styles/typography"

const Container = styled(FullScreenContainer)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Headline = styled(StyledH1)`
  color: black;
  font-family: 'Champion Heavyweight';
  font-size: 2em;
  text-align: center;
`



const IndexPage = () => {
  const { 
      background, 
  }: FourOhFourQueryQuery = useStaticQuery(
      graphql`
      query FourOhFourQuery {
          background: file(
              relativePath: { eq: "section-6/modals/PAGE_6_POPUP_BG_x2.jpg"}
          ) {
              ...backgroundImage
          },
        }
  `)
  return (
    <Layout>
      <SEO title={"El Luchador Tequila: 404"} />
      <Container backgroundImage={background.childImageSharp.fluid.src}>
        <Headline>Page not found</Headline>
        <StyledP color={'black'}>
          <p>Click <Link to="/">here</Link> to return to the homepage.</p>
        </StyledP>

      </Container>
    </Layout>
  )
}

export default IndexPage
